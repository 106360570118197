<template>
  <v-card flat>
    <v-card-title>Overview</v-card-title>

    <div v-if="establishments.length">
      <v-card-text>
        <v-col lg="4">
          <v-select
            v-model="establishment"
            :items="establishments"
            label="Establishment"
            item-text="name"
            outlined
            dense
            hide-details
            return-object
            :menu-props="['offsetY']"
          />
        </v-col>
      </v-card-text>

      <v-card-actions v-if="Boolean(fullMenus.length)">
        <VButtonListMenu
          v-model="selectedMenu"
          :items="fullMenus"
          item-text="name"
        />
      </v-card-actions>

      <v-card-actions v-else>
        <v-btn
          text
          color="pink"
          :to="{name: 'App-MenuCreator-Menus-New'}"
        >
          Create Menu
        </v-btn>
      </v-card-actions>
    </div>

    <v-card-actions v-else>
      <v-btn
        text
        color="pink"
        :to="{name: 'App-Establishments-New'}"
      >
        Create Establishment
      </v-btn>
    </v-card-actions>

    <v-list
      v-for="menuCategory in selectedMenu.menuCategories"
      v-show="Boolean(fullMenus.length)"
      :key="menuCategory.$id"
      dense
    >
      <v-list-item
        dense
      >
        <v-list-item-avatar>
          <v-icon
            class="row-grabbing"
            dense
            @click="()=>null"
          >
            mdi-equal
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ menuCategory.category ? menuCategory.category.name : '' }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <div
        v-for="categoryItem in menuCategory.category ? menuCategory.category.categoryItems : []"
        :key="categoryItem.$id"
      >
        <v-list-item
          dense
        >
          <v-list-item-avatar>
            <v-icon
              class="row-grabbing"
              dense
              @click="()=>null"
            >
              mdi-equal
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-avatar>
            <v-img src="https://picsum.photos/200" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="mx-3">
              {{ categoryItem.item ? categoryItem.item.name : '' }}
            </v-list-item-title>
            <v-list-item-subtitle class="mx-3">
              <v-chip-group>
                <v-chip
                  v-for="itemCustomizer in categoryItem.item ? categoryItem.item.itemCustomizers : []"
                  :key="itemCustomizer.$id"
                  x-small
                >
                  {{ itemCustomizer.customizer ? itemCustomizer.customizer.name : '' }}
                </v-chip>
              </v-chip-group>
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-text-field
              label="Price"
              outlined
              dense
              hide-details
              filled
            />
          </v-list-item-action>
        </v-list-item>
        <v-divider inset />
      </div>
    </v-list>
  </v-card>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex'
import { Establishment } from '@/store/models'
import VButtonListMenu from '@/components/VButtonListMenu'

export default {
  name: 'Overview',
  components: { VButtonListMenu },
  data () {
    return {
      selectedMenu: {}
    }
  },
  computed: {
    ...mapGetters('app', ['activeEstablishment', 'menus']),
    establishments () {
      return Establishment.all()
    },
    establishment: {
      get () {
        return this.activeEstablishment
      },
      set (value) {
        this.setActiveEstablishmentId(value?.id)
      }
    },
    fullMenus () {
      return this.menus.with('menuCategories.category.categoryItems.item.itemCustomizers.customizer').all()
    }
  },
  methods: {
    ...mapMutations('app', ['setActiveEstablishmentId'])
  }
}
</script>

<style scoped>
.row-grabbing:hover {
  cursor: move;
}
</style>
