<template>
  <v-menu
    close-on-content-click
    open-on-hover
    offset-y
    left
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        x-large
        text
        light
        v-bind="attrs"
        v-on="on"
      >
        {{ fields[itemText] }}
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
    </template>

    <v-list
      nav
      dense
    >
      <v-list-item-group
        v-model="fields"
        color="blue darken-3"
        mandatory
        @change="$emit('input', fields)"
      >
        <v-list-item
          v-for="item in items"
          :key="item.$id"
          :value="item"
          :inactive="false"
          dense
        >
          <template v-slot:default="">
            <v-list-item-content>
              <v-list-item-title v-text="item[itemText]" />
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import modelMixin from '@/mixins/modelMixin'
import { isEmpty } from 'lodash'

export default {
  name: 'VButtonListMenu',
  mixins: [modelMixin],
  props: {
    items: {
      type: Array,
      default: () => []
    },
    itemText: {
      type: String,
      default: 'text'
    }
  },
  watch: {
    items: {
      handler (value) {
        if (value.length && isEmpty(this.fields)) {
          this.fields = this.items[0]
          this.$emit('input', this.fields)
        }
      }
    },
    deep: true
  },
  mounted () {
    if (isEmpty(this.fields)) {
      this.fields = this.items[0]
      this.$emit('input', this.fields)
    }
  }
}
</script>
